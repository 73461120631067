import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import ForgotToken from './ForgotToken';
import "leaflet/dist/leaflet.css";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/forgot-token" element={<ForgotToken />} />

      </Routes>
    </Router>
  );
}

export default App;
