import React, { useState, useEffect } from 'react';

function ListPolygonsModal({ show, onClose, token }) {
  const [polygons, setPolygons] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const polygonsPerPage = 5; // Nombre de polygones par page

  useEffect(() => {
    if (show) {
      fetchPolygons();
    }
  }, [show]);

  const fetchPolygons = async () => {
    try {
      const response = await fetch(`https://api.agriguard.app/api-sh/polygons?symbol=modules&lang=fr&token=${token}`);
      const data = await response.json();
      setPolygons(data);
    } catch (error) {
      console.error('Error fetching polygons:', error);
    }
  };

  const disablePolygon = async (polygonId, isActive) => {
    try {
      const response = await fetch(
        `https://api.agriguard.app/api-sh/disable-polygon?symbol=modules&market=${polygonId}&token=${token}`,
        {
          method: 'GET',
        }
      );

      if (!response.ok) {
        throw new Error('Failed to disable polygon');
      }

      setPolygons((prevPolygons) =>
        prevPolygons.map((polygon) =>
          polygon.id === polygonId ? { ...polygon, active: !isActive } : polygon
        )
      );
      console.log(`Polygon ${polygonId} successfully disabled.`);
    } catch (error) {
      console.error('Error disabling polygon:', error);
    }
  };

  const handleDisableClick = (polygonId, isActive) => {
    // Confirmation avant de désactiver le polygone
    const confirmDisable = window.confirm("Êtes-vous sûr de vouloir désactiver ce polygone ?");
    if (confirmDisable) {
      disablePolygon(polygonId, isActive);
    }
  };

  const indexOfLastPolygon = currentPage * polygonsPerPage;
  const indexOfFirstPolygon = indexOfLastPolygon - polygonsPerPage;
  const currentPolygons = polygons.slice(indexOfFirstPolygon, indexOfLastPolygon);
  const totalPages = Math.ceil(polygons.length / polygonsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  if (!show) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        maxWidth: '600px',
        maxHeight: '80vh',
        overflowY: 'auto',
        width: '100%',
      }}
    >
      <button onClick={onClose} style={{ float: 'right', cursor: 'pointer' }}>✖️</button>
      <h3 style={{ marginBottom: '10px', textAlign: 'center' }}>List of Polygons</h3>
      <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left' }}>
        <thead>
          <tr>
            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>ID</th>
            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Name</th>
            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Description</th>
            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Disable</th>
          </tr>
        </thead>
        <tbody>
          {currentPolygons.map((polygon) => (
            <tr key={polygon.id}>
              <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{polygon.id}</td>
              <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{polygon.name}</td>
              <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{polygon.description}</td>
              <td style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
                <button
                  onClick={() => handleDisableClick(polygon.id, polygon.active)}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '18px',
                    color: polygon.active ? 'red' : 'gray', // Rouge si actif, gris si inactif
                  }}
                  title={polygon.active ? 'Désactiver le polygone' : 'Activer le polygone'}
                >
                  &#10006; {/* Symbole unicode pour "X" */}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div style={{ marginTop: '15px', textAlign: 'center' }}>
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          style={{
            marginRight: '10px',
            padding: '5px 10px',
            cursor: currentPage === 1 ? 'default' : 'pointer',
          }}
        >
          Prev
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          style={{
            marginLeft: '10px',
            padding: '5px 10px',
            cursor: currentPage === totalPages ? 'default' : 'pointer',
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ListPolygonsModal;
