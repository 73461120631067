import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ForgotToken() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        try {
            const response = await fetch('https://api.agriguard.app/api-sh/forgot-pw', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
            
                setShowSuccessPopup(true);  
                setTimeout(() => {
                    setShowSuccessPopup(false);
                    navigate('/');
                }, 1000);
            } else {
                const errorText = await response.text();
                setMessage(`Erreur : ${errorText}`);
            }
        } catch (err) {
            console.error('Erreur réseau ou autre :', err);
            setMessage("Une erreur est survenue lors de l'envoi de l'email. Veuillez réessayer plus tard.");
        }

        setLoading(false);
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg w-96 relative">
                <div className="flex flex-col items-center mb-6">
                    <img src="./logonew.jpg" alt="AgriGuard Logo" className="h-12 mb-4" />
                    <h1 className="text-green-500 text-3xl font-bold">Forgotten Token</h1>
                    <p className="text-gray-600 mt-2 text-center">Enter your email.</p>
                </div>

                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="w-full p-3 border border-gray-300 rounded mb-4 focus:outline-none focus:border-green-500"
                    />
                    
                    {message && <p className="text-sm mb-4 text-center">{message}</p>}
                    
                    <button
                        type="submit"
                        className="w-full bg-green-500 text-white p-3 rounded font-semibold hover:bg-green-600"
                        disabled={loading}
                    >
                        {loading ? 'Envoi en cours...' : 'Send Token'}
                    </button>
                </form>

                <div className="text-center mt-4">
                    <a href="/" className="text-green-500 text-sm hover:underline">
                        Back to Login page
                    </a>
                </div>

                {/* Popup de succès */}
                {showSuccessPopup && (
                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 mt-4 bg-green-500 text-white text-sm font-semibold p-3 rounded-lg shadow-lg transition-opacity duration-300">
                        Token envoyé avec succès à votre adresse email !
                    </div>
                )}
            </div>
        </div>
    );
}

export default ForgotToken;
