import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import ListPolygonsModal from './ListPolygonsModal';
import AddPolygonModal from './AddPolygonModal';
import { FaExpand } from 'react-icons/fa'; // Importez l'icône de votre choix

mapboxgl.accessToken = 'pk.eyJ1IjoiYW5hc2JkOTQiLCJhIjoiY20xaHh0eGJqMGlqMTJzcXliNW5jb3lrbCJ9.XReXwIiZ8x99pzQPrsQ6Vg';
const MapboxComponent = ({ onModuleSelect, onParamCodeChange, token, selectedModule }) => {
  const mapRef = useRef(null);
  const modalMapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [modalMap, setModalMap] = useState(null);
  const [moduleLocations, setModuleLocations] = useState([]);
  const [mainMarkers, setMainMarkers] = useState([]);
  const [modalMarkers, setModalMarkers] = useState([]);
  const [polygonData, setPolygonData] = useState(null);
  const [isPolygonVisible, setIsPolygonVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpenList, setIsModalOpenList] = useState(false);
  const [isAddPolygonModalOpen, setIsAddPolygonModalOpen] = useState(false);
  const [newPolygonCoordinates, setNewPolygonCoordinates] = useState('');
  const [moduleParams, setModuleParams] = useState([]);
  const [selectedParamCode, setSelectedParamCode] = useState(null);
  const [market, setMarket] = useState("ST"); // Valeur par défa
  const markersRef = useRef([]); // Pour stocker les marqueurs actuels sans recharger la carte
  const isFetchingRef = useRef(false);
  const mainMarkersRef = useRef([]); // Stocke les marqueurs de la carte principale


  const openModallist = () => setIsModalOpenList(true);
  const closeModallist = () => setIsModalOpenList(false);
 
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  
  // Fonction pour ouvrir le modal d'ajout de polygone après le dessin
  const handlePolygonDraw = (e) => {
    const coordinates = e.features[0].geometry.coordinates[0].map(coord => coord.join(' ')).join(', ');
    setNewPolygonCoordinates(coordinates);
    setIsAddPolygonModalOpen(true);
  };
  const fetchModuleParams = async (module) => {
    try {
      const response = await fetch(`https://api.agriguard.app/api-sh/mapparams?symbol=${module}&lang=fr&token=${token}`);
      const data = await response.json();
      setModuleParams(data);
    } catch (error) {
      console.error('Error fetching module parameters:', error);
    }
  };
  useEffect(() => {
    if (selectedModule) {
      fetchModuleParams(selectedModule);
    }
  }, [selectedModule]);
  const handleSavePolygon = async (polygonData) => {
    try {
      const response = await fetch(`https://api.agriguard.app/api-sh/set-polygon?token=${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(polygonData),
      });

      if (!response.ok) {
        throw new Error('Failed to save polygon');
      }
      alert('Polygon saved successfully!');
    } catch (error) {
      console.error('Error saving polygon:', error);
      alert('Failed to save polygon');
    }
  };
  const calculateCenter = (locations) => {
    if (locations.length === 0) return { lat: 37.0902, lng: -95.7129 }; // Position par défaut
    const latitudes = locations.map((loc) => parseFloat(loc.latitude));
    const longitudes = locations.map((loc) => parseFloat(loc.longitude));
    const avgLat = latitudes.reduce((sum, lat) => sum + lat, 0) / latitudes.length;
    const avgLng = longitudes.reduce((sum, lng) => sum + lng, 0) / longitudes.length;
    return { lat: avgLat, lng: avgLng };
  };

  const getBounds = (locations) => {
    const bounds = new mapboxgl.LngLatBounds();
    locations.forEach((module) => {
      bounds.extend([parseFloat(module.longitude), parseFloat(module.latitude)]);
    });
    return bounds;
  };
  const toggleFullscreenModal = () => {
    setIsModalOpen(!isModalOpen);
  };



  const initMap = (center, container) => {
    const mapboxMap = new mapboxgl.Map({
        container: container,
        style: 'mapbox://styles/mapbox/satellite-v9',
        center: [center.lng, center.lat],
        zoom: 14,
        minZoom: 2,
        maxZoom: 19,
    });

    mapboxMap.addControl(new mapboxgl.NavigationControl());
    const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: { polygon: true, trash: true },
    });
    mapboxMap.addControl(draw);

    // Écouteur pour `draw.create` (souris)
    mapboxMap.on('draw.create', handlePolygonDraw);

    // Ajoutez un écouteur pour `touchend` pour les écrans tactiles
    mapboxMap.on('touchend', (e) => {
        if (draw.getSelected().features.length) {
            handlePolygonDraw(e);
        }
    });

    mapboxMap.on('load', () => {
        if (moduleLocations.length > 0) {
            addMarkersToMap(mapboxMap, moduleLocations, setMainMarkers);
        }
    });

    return mapboxMap;
};

  const addMarkersToMap = (map, locations, markersRef) => {
    // S'assurer que markersRef.current est un tableau
    if (!markersRef.current) {
        markersRef.current = [];
    }

    // Supprime les anciens marqueurs en utilisant markersRef
    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = []; // Réinitialise la référence des marqueurs pour la carte principale

    const newMarkers = locations.map((module) => {
        const markerElement = document.createElement('div');
        const isSelected = selectedModule === module.module_code;

        // Styles de base pour chaque marqueur
        markerElement.style.backgroundColor = isSelected ? `#${module.color}` : 'rgba(255, 255, 255, 0.1)';
        markerElement.style.color = 'black';
        markerElement.style.borderRadius = '50%';
        markerElement.style.width = '40px';
        markerElement.style.height = '40px';
        markerElement.style.display = 'flex';
        markerElement.style.justifyContent = 'center';
        markerElement.style.alignItems = 'center';
        markerElement.innerHTML = module.marker ? `<strong>${module.marker} </strong>` : '';

        // Création du tooltip
        const tooltip = document.createElement('div');
        tooltip.className = 'tooltip';
        tooltip.style.display = 'none';
        tooltip.style.position = 'absolute';
        tooltip.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
        tooltip.style.color = 'white';
        tooltip.style.padding = '5px 10px';
        tooltip.style.borderRadius = '5px';
        tooltip.innerHTML = `<h3>${module.module_name}</h3>`;
        document.body.appendChild(tooltip);

        // Création du marqueur
        const marker = new mapboxgl.Marker(markerElement)
            .setLngLat([parseFloat(module.longitude), parseFloat(module.latitude)])
            .addTo(map);

        // Gestion de l'affichage du tooltip
        markerElement.addEventListener('mouseenter', (e) => {
            tooltip.style.display = 'block';
            tooltip.style.left = `${e.pageX + 10}px`;
            tooltip.style.top = `${e.pageY + 10}px`;
        });

        markerElement.addEventListener('mousemove', (e) => {
            tooltip.style.left = `${e.pageX + 10}px`;
            tooltip.style.top = `${e.pageY + 10}px`;
        });

        markerElement.addEventListener('mouseleave', () => {
            tooltip.style.display = 'none';
        });
        // Fonction de sélection du module
        const selectModule = () => {
          onModuleSelect(module.module_code);
      };

      // Ajoutez les événements `click` et `touchend`
      markerElement.addEventListener('click', selectModule);
      markerElement.addEventListener('touchend', selectModule);

        return marker;
    });

    // Met à jour la référence des marqueurs pour la carte principale
    markersRef.current = newMarkers;
};




  const addPolygonsToMap = (map, polygons) => {
    // Vérifier si la source existe déjà, sinon, la créer
    if (!map.getSource('polygon')) {
      map.addSource('polygon', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: polygons, // Ajout des polygones à la source
        },
      });
  
      // Ajouter les couches de polygones
      map.addLayer({
        id: 'polygon-fill',
        type: 'fill',
        source: 'polygon',
        layout: {},
        paint: {
          'fill-color': 'rgba(0, 150, 255, 0.3)', // Couleur de remplissage
          'fill-opacity': 1,
        },
      });
  
      map.addLayer({
        id: 'polygon-outline',
        type: 'line',
        source: 'polygon',
        layout: {},
        paint: {
          'line-color': 'black',  // Couleur du contour
          'line-width': 2,       // Largeur du contour
        },
      });
    } else {
      // Si la source existe déjà, mettre à jour ses données
      map.getSource('polygon').setData({
        type: 'FeatureCollection',
        features: polygons, // Met à jour les polygones visibles
      });
    }
  };
  
  
  const togglePolygons = (targetMap) => {
    // Vérifier que la carte est prête
    if (targetMap && targetMap.isStyleLoaded()) {
        if (isPolygonVisible) {
            // Masquer les polygones si `isPolygonVisible` est vrai
            if (targetMap.getLayer("polygon-fill")) {
                targetMap.removeLayer("polygon-fill");
            }
            if (targetMap.getLayer("polygon-outline")) {
                targetMap.removeLayer("polygon-outline");
            }
            if (targetMap.getSource("polygon")) {
                targetMap.removeSource("polygon");
            }
            setIsPolygonVisible(false); // Mettre à jour l'état pour "caché"
        } else if (polygonData && polygonData.features.length > 0) {
            // Afficher les polygones
            addPolygonsToMap(targetMap, polygonData.features);
            setIsPolygonVisible(true); // Mettre à jour l'état pour "visible"
        }
    } else {
        console.warn("La carte n'est pas prête pour afficher/masquer les polygones.");
    }
};



  
// Fonction pour récupérer les localisations des modules
const fetchModuleLocations = async (market) => {
  try {
      if (isFetchingRef.current) return; // Ne pas lancer un autre fetch si déjà en cours
      isFetchingRef.current = true; // Indique que l'appel est en cours

      const response = await fetch(`https://api.agriguard.app/api-sh/agrimodules?symbol=modules&market=${market}&lang=fr&token=${token}`);
      const data = await response.json();
      console.log("objectmodule", data);
      
      // Mettre à jour l'état avec les données récupérées
      if (data) {
          setModuleLocations(data); // Met à jour les localisations des modules
          updateMarkers(data); // Met à jour les marqueurs
      }
  } catch (error) {
      console.error('Error fetching module locations:', error);
  } finally {
      isFetchingRef.current = false; // Marque la fin de l'appel
  }
};

// Effet pour charger les modules lorsqu'un marché est sélectionné
useEffect(() => {
  if (market) {
      fetchModuleLocations(market);
      console.log(market);
  }
}, [market]);

// Fonction pour gérer le changement de marché
const handleMarketChange = (selectedMarket) => {
  setMarket(selectedMarket);
  onParamCodeChange(selectedMarket);
};

// Modification de updateMarkers pour gérer les cartes principale et modal
const updateMarkers = (newLocations) => {
  if (map) {
      addMarkersToMap(map, newLocations, mainMarkersRef);
  }
  if (modalMap) {
      addMarkersToMap(modalMap, newLocations, modalMarkers);
  }
};
  const fetchPolygonData = async () => {
    try {
      const response = await fetch(`https://api.agriguard.app/api-sh/polygons?symbol=modules&lang=fr&token=${token}`);
      const data = await response.json();
  
      // Filtrer les polygones pour ne garder que ceux avec active = 1
      const activePolygons = data.filter((polygon) => polygon.active === 1);
      console.log("object",activePolygons)
      // Convertir les polygones filtrés en GeoJSON
      const geoJsonPolygons = {
        type: "FeatureCollection",
        features: activePolygons.map((polygon) => ({
          type: "Feature",
          geometry: polygon.geom, // Assurez-vous que `polygon.geom` contient les coordonnées de la géométrie
          properties: {
            name: polygon.name,
            description: polygon.description,
          },
        })),
      };
  
      setPolygonData(geoJsonPolygons);
    } catch (error) {
      console.error("Error fetching polygon data:", error);
    }
  };
  const fetchMarkerData = async (paramCode) => {
    try {
      const response = await fetch(`https://api.agriguard.app/api-sh/agriparams?symbol=modules&lang=fr&token=${token}`);
      const data = await response.json();
      const filteredData = data.find((param) => param.param_code === paramCode);
      
      setModuleLocations(moduleLocations.map((module) => ({
        ...module,
        marker: filteredData ? filteredData.param_value : module.marker
      })));
    } catch (error) {
      console.error("Error fetching marker data:", error);
    }
  };


  useEffect(() => {
    if (selectedParamCode) {
      fetchMarkerData(selectedParamCode);
    }
  }, [selectedParamCode]);
  

  useEffect(() => {
    fetchModuleLocations();
    fetchPolygonData();
  }, [token]);
  useEffect(() => {
    if (map) {
      addMarkersToMap(map, moduleLocations, setMainMarkers);
    }
  }, [moduleLocations, selectedParamCode, map]);
useEffect(() => {
  if (map) {
    addMarkersToMap(map, moduleLocations, setMainMarkers);
  }
}, [moduleLocations, selectedParamCode, map]);
 // Initialisation de la carte seulement une fois
 useEffect(() => {
  if (!map && moduleLocations.length > 0) {
    const center = calculateCenter(moduleLocations);
    const newMap = initMap(center, mapRef.current);
    setMap(newMap);
  }
}, [moduleLocations]);
useEffect(() => {
  if (map && moduleLocations.length > 0) {
      addMarkersToMap(map, moduleLocations, mainMarkersRef);
  }
}, [map, moduleLocations, selectedModule]);



  useEffect(() => {
    if (isModalOpen && modalMapRef.current && !modalMap) {
      const center = calculateCenter(moduleLocations);
      const newModalMap = initMap(center, modalMapRef.current);
      setModalMap(newModalMap);
      addMarkersToMap(newModalMap, moduleLocations, setModalMarkers);
    }
  }, [isModalOpen, modalMapRef, modalMap, moduleLocations]);

  const openModalWithMap = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (modalMap) {
      modalMarkers.forEach(marker => marker.remove());
      modalMap.remove();
      setModalMap(null);
    }
  };


  useEffect(() => {
    if (isModalOpen && modalMap && polygonData && isPolygonVisible) {
      addPolygonsToMap(modalMap, polygonData.features);
    }
  }, [isModalOpen, modalMap, polygonData, isPolygonVisible]);

 

  return (
    <div style={{ 
      position: 'relative', 
      width: '100%', 
      maxWidth: '900px',  
      margin: '20px auto',
      padding: '0 20px'  
  }}>
      <div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 1 }}>
        <select
          onChange={(e) => {
            const selectedMarket = e.target.value;
            if (selectedMarket) {
              setSelectedParamCode(selectedMarket);
              handleMarketChange(selectedMarket);
            }
          }}
          value={selectedParamCode}
          style={{
            padding: '10px 12px',
            borderRadius: '8px',
            backgroundColor: 'rgba(30, 30, 30, 0.8)', // Fond sombre et translucide
            color: '#FFF', // Texte blanc pour contraste
            border: '1px solid rgba(255, 255, 255, 0.2)', // Bordure douce
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // Ombre subtile
            outline: 'none',
            appearance: 'none', // Pour enlever le style par défaut
            fontSize: '16px',
            fontWeight: '500',
            transition: 'background-color 0.3s, border 0.3s',
            cursor: 'pointer',
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgba(50, 50, 50, 0.9)')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = 'rgba(30, 30, 30, 0.8)')}
          onFocus={(e) => (e.target.style.border = '1px solid rgba(255, 255, 255, 0.5)')}
          onBlur={(e) => (e.target.style.border = '1px solid rgba(255, 255, 255, 0.2)')}
        >
          {moduleParams.length > 0 ? (
            moduleParams.map((param) => (
              <option key={param.param_code} value={param.param_code} style={{ color: '#000' }}>
                {param.param_text} {selectedParamCode === param.param_code ? "✓" : ""}
              </option>
            ))
          ) : (
            <option value="" disabled>Loading...</option>
          )}
        </select>

      </div>
    <div
      ref={mapRef}
      style={{
        width: 'calc(100% + 40px)',     
        height: '500px',
        borderRadius: '15px',
        overflow: 'hidden',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '5px solid #ADD8E6',    
        margin: '0 -20px'               
      }}
    />
     {/* Bouton pour ouvrir le modal en plein écran */}
      <button
        onClick={toggleFullscreenModal}
        className="absolute top-60 right-2 w-9 h-10 bg-white text-gray-700 border-2 border-black rounded-md shadow-lg flex items-center justify-center"
        title="Agrandir la carte"
      >
        <FaExpand />
      </button>

      {/* Modal pour l'ajout de polygone */}
      <AddPolygonModal
        show={isAddPolygonModalOpen}
        onClose={() => setIsAddPolygonModalOpen(false)}
        onSave={handleSavePolygon}
        polygonCoordinates={newPolygonCoordinates}
      />

    
        {/* Bouton Options en bas à droite */}
        <div style={{ position: 'absolute', bottom: '280px', right: '10px' }}>
        <button
          onClick={toggleDropdown}
          style={{
            backgroundColor: 'white',  // Fond blanc
            color: '#555',  // Couleur du texte (gris foncé)
            border: '2px solid black',  // Bordure noire
            borderRadius: '5px',  // Bordures arrondies
            padding: '10px',  // Espacement interne
            cursor: 'pointer',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',  // Ombre pour effet de profondeur
            width: '35px',  // Largeur réduite
            height: '40px',  // Hauteur définie pour que le bouton reste carré
            display: 'flex',  // Centrage de l'icône
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          ⚙️
        </button>
        
        {/* Menu Dropdown */}
   
        {isDropdownOpen && (
          <div style={{
            position: 'absolute',
            bottom: '50px',
            right: '0',
            backgroundColor: '#FFF',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            borderRadius: '5px',
            overflow: 'hidden',
            zIndex: 1000,
            width: '200px', 
          }}>
            <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
              <li style={{ display: 'flex', alignItems: 'center', padding: '10px 15px' }}>
                <label style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '10px' }}>Display Polygon</span>
                  <div
                    style={{
                      width: '40px',
                      height: '20px',
                      backgroundColor: isPolygonVisible ? 'green' : 'gray', 
                      borderRadius: '20px',
                      position: 'relative',
                      cursor: 'pointer',
                      transition: 'background-color 0.2s',
                    }}
                    onClick={() => togglePolygons(isModalOpen ? modalMap : map)}
                    >
                    <div
                      style={{
                        width: '18px',
                        height: '18px',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        position: 'absolute',
                        top: '1px',
                        left: isPolygonVisible ? '20px' : '1px',
                        transition: 'left 0.2s',
                      }}
                    ></div>
                  </div>
                </label>
              </li>
              <div>
                <ul>
                  <li
                    style={{ padding: '10px 20px', cursor: 'pointer' }}
                    onClick={openModallist}
                  >
                    List Polygons
                  </li>
                </ul>
                {/* Intégrer le modal et passer les props show et onClose */}
                <ListPolygonsModal show={isModalOpenList} onClose={closeModallist}token={token} />
              </div>
             
            </ul>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ position: 'absolute', top: '21px', left: '125px', zIndex: 1 }}>
        <select
          onChange={(e) => {
            const selectedMarket = e.target.value;
            if (selectedMarket) {
              setSelectedParamCode(selectedMarket);
              handleMarketChange(selectedMarket);
            }
          }}
          value={selectedParamCode}
          style={{
            padding: '10px 12px',
            borderRadius: '8px',
            backgroundColor: 'rgba(30, 30, 30, 0.8)', // Fond sombre et translucide
            color: '#FFF', // Texte blanc pour contraste
            border: '1px solid rgba(255, 255, 255, 0.2)', // Bordure douce
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // Ombre subtile
            outline: 'none',
            appearance: 'none', // Pour enlever le style par défaut
            fontSize: '16px',
            fontWeight: '500',
            transition: 'background-color 0.3s, border 0.3s',
            cursor: 'pointer',
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgba(50, 50, 50, 0.9)')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = 'rgba(30, 30, 30, 0.8)')}
          onFocus={(e) => (e.target.style.border = '1px solid rgba(255, 255, 255, 0.5)')}
          onBlur={(e) => (e.target.style.border = '1px solid rgba(255, 255, 255, 0.2)')}
        >
          {moduleParams.length > 0 ? (
            moduleParams.map((param) => (
              <option key={param.param_code} value={param.param_code} style={{ color: '#000' }}>
                {param.param_text} {selectedParamCode === param.param_code ? "✓" : ""}
              </option>
            ))
          ) : (
            <option value="" disabled>Loading...</option>
          )}
        </select>

      </div>
          <div
            ref={modalMapRef}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
          {/* Bouton Options au centre vertical à droite */}
          <div
            style={{
              position: 'absolute',
              top: '28%',
              right: '0.4vw',  // Utilisation d'unités vw pour rester responsive
              transform: 'translateY(-50%)',
            }}
          >
            <button
              onClick={toggleDropdown}
              style={{
                backgroundColor: 'white',
                color: '#555',
                border: '2px solid black',
                borderRadius: '5px',
                padding: '10px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                width: '33px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '20px',
              }}
            >
              ⚙️
            </button>
            {/* Menu Dropdown */}

            {isDropdownOpen && (
              <div style={{
                position: 'absolute',
                bottom: '50px',
                right: '0',
                backgroundColor: '#FFF',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                borderRadius: '5px',
                overflow: 'hidden',
                zIndex: 1000,
                width: '200px',
              }}>
                <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                  <li style={{ display: 'flex', alignItems: 'center', padding: '10px 15px' }}>
                    <label style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <span style={{ marginRight: '10px' }}>Display Polygon</span>
                      <div
                        style={{
                          width: '40px',
                          height: '20px',
                          backgroundColor: isPolygonVisible ? 'green' : 'gray',
                          borderRadius: '20px',
                          position: 'relative',
                          cursor: 'pointer',
                          transition: 'background-color 0.2s',
                        }}
                        onClick={() => togglePolygons(isModalOpen ? modalMap : map)}
                      >
                        <div
                          style={{
                            width: '18px',
                            height: '18px',
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: '1px',
                            left: isPolygonVisible ? '20px' : '1px',
                            transition: 'left 0.2s',
                          }}
                        ></div>
                      </div>
                    </label>
                  </li>
                  <div>
                    <ul>
                      <li
                        style={{ padding: '10px 20px', cursor: 'pointer' }}
                        onClick={openModallist}
                      >
                        List Polygons
                      </li>
                    </ul>
                    {/* Intégrer le modal et passer les props show et onClose */}
                    <ListPolygonsModal show={isModalOpenList} onClose={closeModallist} token={token} />
                  </div>

                </ul>
              </div>
            )}
          </div>

          <button onClick={closeModal} style={{
            position: 'absolute',
            top: '20px',
            left: '20px',
            backgroundColor: '#F5F5F5',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            padding: '10px 20px',
            cursor: 'pointer',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}>
            ✖️
          </button>
        </div>

      )}
{isAddPolygonModalOpen && (
  <div
    style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',  // Fond sombre transparent pour centrer l'attention
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1100, // Plus élevé que le fond d'écran en plein écran
    }}
  >
    <div
      style={{
        backgroundColor: 'white',
        padding: '20px 30px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        width: '400px',  // Largeur fixe pour plus de consistance
        maxWidth: '90%',  // Réduction de largeur sur écrans petits
      }}
    >
      <button 
        onClick={() => setIsAddPolygonModalOpen(false)} 
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          color: '#333',
          fontSize: '18px',
          fontWeight: 'bold',
          cursor: 'pointer',
          float: 'right',
        }}
      >
        ✖️
      </button>

      <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>Add New Polygon</h2>

      <AddPolygonModal
        show={isAddPolygonModalOpen}
        onClose={() => setIsAddPolygonModalOpen(false)}
        onSave={handleSavePolygon}
        polygonCoordinates={newPolygonCoordinates}
      />
    </div>
  </div>
)}

    </div>
  );
};

export default MapboxComponent;
