import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import LineChartComponent from './LineChartComponent';
import TemperatureInversionChart from './TemperatureInversionChart';
import MapboxComponent from './MapboxComponent';

function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedModule, setSelectedModule] = useState('A01');
  const [token, setToken] = useState(null);
  const [moduleParams, setModuleParams] = useState([]);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [paramCode, setParamCode] = useState("AT"); // Ajoutez cette ligne


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const newToken = queryParams.get('token');

    if (!newToken) {
      navigate('/');
      return;
    }

    if (newToken && newToken !== token) {
      setToken(newToken);
    }
  }, [location, token, navigate]);

  useEffect(() => {
    const fetchParams = async () => {
      try {
        const response = await axios.get('https://api.agriguard.app/api-sh/agriparams', {
          params: {
            symbol: selectedModule,
            lang: 'fr',
            token: token,
          },
        });

        if (response.status === 401) {
          handleError();
          return;
        }

        const params = response.data.map((param) => param.param_code);

        if (params.length === 0) {
          handleError();
        } else {
          setModuleParams(params);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des paramètres du module :', error);
        handleError();
      }
    };

    if (selectedModule && token) {
      fetchParams();
    }
  }, [selectedModule, token, navigate]);

  const handleError = () => {
    setShowErrorDialog(true);
    setTimeout(() => {
      setShowErrorDialog(false);
      navigate('/');
    }, 1000);
  };

  // Filtre pour exclure 'TI' si présent
  const filteredParams = moduleParams.filter((paramCode) => paramCode !== 'TI');

  return (
    <div className="p-4 md:p-8 bg-gray-100 min-h-screen flex flex-col justify-between relative">
      {showErrorDialog && (
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 mt-4 bg-red-500 text-white text-sm font-semibold p-4 rounded-lg shadow-lg transition-opacity duration-300">
          No data available
        </div>
      )}
      
      <div className="flex flex-col md:flex-row gap-4">
        
        {/* Colonne de gauche pour Header et Map */}
        <div className="md:w-1/2 flex flex-col space-y-4">
        

          <Header
            onModuleChange={(module) => setSelectedModule(module)}
            token={token}
            selectedModule={selectedModule} // Passe `selectedModule`
            paramCode={paramCode}
          />

          {token && (
            <MapboxComponent
              onModuleSelect={(module) => setSelectedModule(module)}
              onParamCodeChange={(code) => setParamCode(code)}
              token={token}
              selectedModule={selectedModule}
            />
          )}
        </div>
        

        {/* Colonne de droite pour les graphiques dans une seule colonne */}
        <div className="md:w-1/2 flex flex-col items-center gap-4">
          {filteredParams.map((paramCode, index) => (
            <LineChartComponent 
              key={index} 
              moduleCode={selectedModule} 
              paramCode={paramCode} 
              widgetColor="#dddddd7d" 
              token={token} 
              className="w-full" 
            />
          ))}
          {moduleParams.includes('TI') && (
            <TemperatureInversionChart 
              moduleCode={selectedModule} 
              paramCode="TI" 
              widgetColor="#dddddd7d" 
              token={token} 
              className="w-full"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
