import React, { useState, useEffect } from 'react';

const Header = ({ onModuleChange, token, selectedModule, paramCode }) => {
  const [modules, setModules] = useState([]);
  const [headerData, setHeaderData] = useState({ param_value: 'N/A', param_text: 'No data available' });

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await fetch(`https://api.agriguard.app/api-sh/agrimodules?symbol=modules&lang=fr&token=${token}`);
        const data = await response.json();
        if (data && Array.isArray(data)) {
          setModules(data);
          if (!selectedModule) {
            onModuleChange(data[0].module_code); // Initialise seulement si aucun module n'est sélectionné
          }
        } else {
          console.error('Modules non trouvés ou format inattendu.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des modules :', error);
      }
    };

    fetchModules();
  }, [token, onModuleChange, selectedModule]);

  useEffect(() => {
    const fetchHeaderData = async () => {
      if (selectedModule && paramCode) {
        try {
          const response = await fetch(`https://api.agriguard.app/api-sh/agriheader?symbol=${selectedModule}&market=${paramCode}&lang=fr&token=${token}`);
          const data = await response.json();
          if (data && data.length > 0 && data[0].param_value && data[0].param_text) {
            setHeaderData(data[0]);
          } else {
            console.error('Données de header non valides ou format inattendu.');
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des données de header :', error);
        }
      }
    };

    fetchHeaderData();
  }, [selectedModule, paramCode, token]);

  const handleModuleChange = (event) => {
    const selected = modules.find((mod) => mod.module_code === event.target.value);
    onModuleChange(selected.module_code); // Met à jour `selectedModule`
  };

  return (
    <div className="relative mt-4">
      <div className="flex items-center justify-center">
        <select
          className="w-full md:w-1/2 lg:w-1/3 max-w-sm text-black text-sm text-center font-medium bg-white border border-gray-300 rounded-lg shadow-sm p-2 h-12 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
          value={selectedModule || ""}
          onChange={handleModuleChange}
        >
          {modules.map((mod) => (
            <option key={mod.module_code} value={mod.module_code}>
              {mod.module_name}
            </option>
          ))}
        </select>
      </div>
      <div className="text-center mt-2">
        <p className="text-4xl text-green-600 font-bold">
          {headerData.param_value}
        </p>
        <p className="text-green-600 font-medium">{headerData.param_text}</p>
      </div>
    </div>
  );
};

export default Header;
