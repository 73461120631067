import React, { useState } from 'react';

function AddPolygonModal({ show, onClose, onSave, polygonCoordinates }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleSave = async () => {
    const polygonData = {
      name,
      description,
      polygon: polygonCoordinates, // Coordonnées du polygone dessiné
    };
    
    await onSave(polygonData);
    onClose();
  };

  if (!show) return null;

  return (
    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', zIndex: 1000, maxWidth: '400px', width: '100%' }}>
      <button onClick={onClose} style={{ float: 'right', cursor: 'pointer' }}>✖️</button>
      <h3 style={{ textAlign: 'center' }}>Add New Polygon</h3>
      <input type="text" placeholder="Polygon Name" value={name} onChange={(e) => setName(e.target.value)} style={{ width: '100%', marginBottom: '10px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
      <textarea placeholder="Polygon Description" value={description} onChange={(e) => setDescription(e.target.value)} style={{ width: '100%', marginBottom: '10px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
      <button onClick={handleSave} style={{ width: '100%', padding: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Save Polygon</button>
    </div>
  );
}

export default AddPolygonModal;
