import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function Login() {
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [captchaAnswer, setCaptchaAnswer] = useState('');
  const [captchaQuestion, setCaptchaQuestion] = useState(generateCaptcha());
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const urlToken = queryParams.get('token');

    if (!urlToken) {
      navigate('/');
    }
  }, [location, navigate]);

  function generateCaptcha() {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    return `${num1} + ${num2} = ?`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);


    const [num1, , num2] = captchaQuestion.split(' ');
    const correctAnswer = parseInt(num1) + parseInt(num2);

    if (parseInt(captchaAnswer) !== correctAnswer) {
      setError('Incorrect CAPTCHA answer. Please try again.');
      setCaptchaQuestion(generateCaptcha());
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `https://api.agriguard.app/api-sh/agrimodules?symbol=modules&lang=fr&token=${token}`
      );
      if (response.ok) {
        const data = await response.json();
        console.log('API Data:', data);

        if (data && data.length > 0) {
          setApiData(data);
          localStorage.setItem('authToken', token);
          navigate(`/dashboard?token=${token}`);
        } else {
          setError('Token invalide ou aucune donnée trouvée. Veuillez vérifier le token.');
        }
      } else {
        const errorText = await response.text();
        console.error('API Error:', errorText);
        setError('Échec de la validation du token. Veuillez réessayer plus tard.');
      }
    } catch (err) {
      console.error('Erreur réseau ou autre :', err);
      setError('Une erreur est survenue lors de la validation du token.');
    }

    setLoading(false);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
        <div className="flex flex-col items-center mb-6">
          <img src="./logonew.jpg" alt="AgriGuard Logo" className="h-12 mb-4" />
          <h1 className="text-green-500 text-3xl font-bold">Welcome!</h1>
          <p className="text-gray-600 mt-2 text-center">Enter your token.</p>
        </div>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={token}
            onChange={(e) => {
              setToken(e.target.value);
              setError('');
            }}
            placeholder="Enter your token"
            className={`w-full p-3 border ${error ? 'border-red-500' : 'border-gray-300'} rounded mb-4 focus:outline-none focus:border-green-500`}
          />

          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

          <label className="block mb-2 text-sm text-gray-600">{captchaQuestion}</label>
          <input
            type="text"
            value={captchaAnswer}
            onChange={(e) => setCaptchaAnswer(e.target.value)}
            placeholder="Your answer"
            className={`w-full p-3 border ${error ? 'border-red-500' : 'border-gray-300'} rounded mb-4 focus:outline-none focus:border-green-500`}
          />

          <button
            type="submit"
            className="w-full bg-green-500 text-white p-3 rounded font-semibold hover:bg-green-600"
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Login'}
          </button>
        </form>

        <div className="text-center mt-4">
          <a href="/forgot-token" className="text-green-500 text-sm hover:underline">
            Do you forgot your token? Click here.
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
